import axios from "axios";

// USER
export async function requestGetUserList({
	page = 1,
	limit = 10,
	order = "name:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/master/users?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestAddUser(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/master/users/add`, data);
}

export async function requestEditUser(data) {
	return await axios.put(`${process.env.REACT_APP_API_URL}/master/users/edit`, data);
}
// USER

// FOOD
export async function requestGetFoodList({
	page = 1,
	limit = 10,
	order = "name:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/master/foods?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestAddFood(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/master/foods/add`, data);
}

export async function requestEditFood(id, data) {
	return await axios.put(`${process.env.REACT_APP_API_URL}/master/foods/${id}/edit`, data);
}

export async function requestDeleteFood(id) {
	return await axios.delete(`${process.env.REACT_APP_API_URL}/master/foods/${id}/delete`);
}
// FOOD

// RECIPE
export async function requestGetRecipeList({
	page = 1,
	limit = 10,
	order = "name:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/master/recipes?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestAddRecipe(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/master/recipes/add`, data);
}

export async function requestEditRecipe(id, data) {
	return await axios.put(`${process.env.REACT_APP_API_URL}/master/recipes/${id}/edit`, data);
}

export async function requestDeleteRecipe(id) {
	return await axios.delete(`${process.env.REACT_APP_API_URL}/master/recipes/${id}/delete`);
}
// RECIPE
