/* eslint-disable react-hooks/exhaustive-deps */
import {
	Button,
	Form,
	Input,
	Modal,
	App,
	Popconfirm,
	Spin,
	Radio,
	Row,
	Col,
	InputNumber,
	Typography,
	Tooltip,
	Select,
	Space,
	Upload,
	message,
	Image,
} from "antd";
import React, { useEffect, useState } from "react";
import {
	requestAddRecipe,
	requestEditRecipe,
	requestGetFoodList,
} from "../../../../services/master.service";
import {
	DeleteOutlined,
	DownloadOutlined,
	InboxOutlined,
	MinusCircleOutlined,
	PlusOutlined,
	QuestionCircleOutlined,
	RotateLeftOutlined,
	RotateRightOutlined,
	SwapOutlined,
	UndoOutlined,
	ZoomInOutlined,
	ZoomOutOutlined,
} from "@ant-design/icons";

const RecipeFormModal = ({ recipe, isVisible, isLoading, onLoading, onClose, onSuccess }) => {
	const { notification } = App.useApp();
	const [foods, setFoods] = useState([]);
	const [isSearching, setIsSearching] = useState(false);
	const [images, setImages] = useState([]);
	const [imageUrls, setImageUrls] = useState([]);
	const [deletedImageIds, setDeletedImageIds] = useState([]);

	const [form] = Form.useForm();

	const draggerProps = {
		onRemove: (file) => {
			const index = images.indexOf(file);
			const newFileList = images.slice();
			newFileList.splice(index, 1);
			setImages(newFileList);
		},
		beforeUpload: (file) => {
			const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
			if (!isJpgOrPng) {
				message.error("You can only upload JPG/PNG file!");
				return;
			}

			const isLt2M = file.size / 1024 / 1024 < 2;
			if (!isLt2M) {
				message.error("Image must smaller than 2MB!");
				return;
			}

			setImages([...images, file]);
			return false;
		},
		fileList: images,
	};

	useEffect(() => {
		if (recipe) {
			form.setFieldsValue({
				name: recipe.name,
				description: recipe.description,
				ingredients: recipe.ingredients,
				instructions: recipe.instructions,
				duration: {
					preparation: recipe?.duration?.preparation ?? 0,
					cooking: recipe?.duration?.cooking ?? 0,
					rest: recipe?.duration?.rest ?? 0,
				},
				type: recipe.type,
				difficulty: recipe.difficulty,
				xp: recipe.xp,
				serving: {
					amount: recipe?.serving?.amount ?? 0,
					unit: recipe?.serving?.unit ?? "",
				},
				calorie: {
					total: recipe?.calorie?.total ?? 0,
					carbohydrate: recipe?.calorie?.carbohydrate ?? 0,
					protein: recipe?.calorie?.protein ?? 0,
					fat: recipe?.calorie?.fat ?? 0,
				},
				status: recipe.status,
			});

			setImageUrls(recipe.images.map((image) => ({ _id: image._id, url: image.url })));
			fetchFoods("", `id:${recipe.ingredients.map((ingredient) => ingredient.foodId).join("|")}`);
		} else {
			form.resetFields();
			setDeletedImageIds([]);
			setImages([]);
			setImageUrls([]);
		}
	}, [recipe]);

	useEffect(() => {
		if (!isVisible) {
			setImages([]);
			setImageUrls([]);
			setDeletedImageIds([]);
			form.resetFields();
		}
	}, [isVisible]);

	const fetchFoods = (keyword, filter) => {
		setIsSearching(true);
		requestGetFoodList({
			page: 1,
			limit: 100,
			keyword: keyword,
			filter: filter,
		})
			.then((response) => {
				if (response.data.code === 200) {
					setFoods(response.data.data);
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: response.data.message,
					});
				}
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setIsSearching(false);
			});
	};

	const handleSubmit = () => {
		form
			.validateFields()
			.then((values) => {
				onLoading(true);

				const formData = new FormData();
				formData.append("name", values.name);
				formData.append("description", values.description);
				formData.append("ingredients", JSON.stringify(values.ingredients));
				formData.append("instructions", JSON.stringify(values.instructions));
				formData.append("duration", JSON.stringify(values.duration));
				formData.append("type", values.type);
				formData.append("difficulty", values.difficulty);
				formData.append("xp", values.xp);
				formData.append("serving", JSON.stringify(values.serving));
				formData.append("calorie", JSON.stringify(values.calorie));
				formData.append("status", values.status);
				if (images.length > 0) {
					for (let i = 0; i < images.length; i++) {
						formData.append("images", images[i]);
					}
				}
				if (deletedImageIds.length > 0) {
					formData.append("deletedImageIds", JSON.stringify(deletedImageIds));
				}

				if (recipe) {
					requestEditRecipe(recipe._id, formData)
						.then((response) => {
							notification["success"]({
								message: "Good job!",
								description: response.data.message,
							});
							setImages([]);
							setImageUrls([]);
							form.resetFields();
							onSuccess();
						})
						.catch((error) => {
							if (error.response && error.response.data) {
								notification["error"]({
									message: "Kesalahan!",
									description: error.response.data.message,
								});
							} else {
								notification["error"]({
									message: "Kesalahan!",
									description: error.message,
								});
							}
						})
						.finally(() => {
							onLoading(false);
						});
				} else {
					requestAddRecipe(formData)
						.then((response) => {
							notification["success"]({
								message: "Good job!",
								description: response.data.message,
							});
							setImages([]);
							setImageUrls([]);
							form.resetFields();
							onSuccess();
						})
						.catch((error) => {
							if (error.response && error.response.data) {
								notification["error"]({
									message: "Kesalahan!",
									description: error.response.data.message,
								});
							} else {
								notification["error"]({
									message: "Kesalahan!",
									description: error.message,
								});
							}
						})
						.finally(() => {
							onLoading(false);
						});
				}
			})
			.catch((error) => {});
	};

	const handleClose = () => {
		onClose();
	};

	const handleSearch = (value) => {
		// wait 1s before search, if user still typing, reset the timer
		if (value) {
			setTimeout(() => {
				fetchFoods(value);
			}, 1000);
		} else {
			setFoods([]);
		}
	};

	const onDownload = (url) => {
		const link = document.createElement("a");
		link.href = url;
		link.setAttribute("download", "image.jpg");
		link.setAttribute("target", "_blank");
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	const onDeleteImage = (id) => {
		if (!deletedImageIds.includes(id)) {
			setDeletedImageIds([...deletedImageIds, id]);
		}
		setImageUrls(imageUrls.filter((image) => image._id !== id));
	};

	return (
		<>
			<Modal
				width={1024}
				title={recipe === null || recipe === undefined ? "Tambah Resep" : "Ubah Resep"}
				open={isVisible}
				cancelText="Batal"
				onCancel={handleClose}
				footer={[
					<Button key={1} onClick={handleClose}>
						Batal
					</Button>,
					<Popconfirm
						key={2}
						onConfirm={handleSubmit}
						title="Yakin akan menyimpan data resep?"
						okText="Ya"
						cancelText="Tidak">
						<Button type="primary">{recipe ? "Simpan" : "Tambah"}</Button>
					</Popconfirm>,
				]}>
				<Spin spinning={isLoading}>
					<Form form={form} layout="vertical" requiredMark="optional">
						<Form.Item
							style={{ marginTop: 12, marginBottom: 0 }}
							label="Nama"
							name="name"
							rules={[{ required: true, message: "Masukkan nama" }]}>
							<Input style={{ width: "100%" }} placeholder="Nama" />
						</Form.Item>
						<Form.Item
							style={{ marginTop: 12, marginBottom: 0 }}
							label="Deskripsi"
							name="description"
							rules={[{ required: true, message: "Masukkan deskripsi" }]}>
							<Input.TextArea style={{ width: "100%" }} placeholder="Deskripsi" />
						</Form.Item>
						<Typography.Paragraph
							style={{
								marginTop: 12,
								marginBottom: 0,
								paddingBottom: 8,
							}}>
							Bahan-bahan
							<Tooltip
								placement="top"
								title="Tambahkan bahan-bahan yang dibutuhkan untuk membuat resep">
								<QuestionCircleOutlined
									style={{
										marginLeft: 4,
										color: "rgba(0, 0, 0, 0.45)",
									}}
								/>
							</Tooltip>
						</Typography.Paragraph>
						<Form.List name="ingredients">
							{(fields, { add, remove }) => (
								<>
									{fields.map(({ key, name, ...restField }) => (
										<Row
											key={key}
											gutter={12}
											style={{
												marginBottom: 12,
											}}>
											<Col flex={1}>
												<Space.Compact
													style={{ width: "100%" }}
													direction="horizontal"
													size="middle">
													<Form.Item
														{...restField}
														name={[name, "quantity"]}
														rules={[
															{
																required: true,
																message: "Kuantitas wajib diisi",
															},
														]}
														noStyle
														style={{
															marginBottom: 0,
														}}>
														<InputNumber
															min={0}
															placeholder="Kuantitas"
															style={{
																width: "100%",
															}}
														/>
													</Form.Item>
													<Form.Item
														{...restField}
														name={[name, "unit"]}
														noStyle
														rules={[
															{
																required: true,
																message: "Satuan wajib diisi",
															},
														]}
														style={{
															marginBottom: 0,
														}}>
														<Select
															placeholder="Satuan"
															style={{
																width: "100%",
															}}>
															<Select.Option value="KG">Kilogram</Select.Option>
															<Select.Option value="GR">Gram</Select.Option>
															<Select.Option value="ML">Mililiter</Select.Option>
															<Select.Option value="L">Liter</Select.Option>
															<Select.Option value="PCS">Pcs/biji</Select.Option>
															<Select.Option value="SP">Sendok</Select.Option>
															<Select.Option value="TSP">Sendok Teh</Select.Option>
															<Select.Option value="TBSP">Sendok Makan</Select.Option>
														</Select>
													</Form.Item>
												</Space.Compact>
											</Col>
											<Col flex={1}>
												<Form.Item
													{...restField}
													name={[name, "foodId"]}
													rules={[
														{
															required: true,
															message: "Bahan wajib diisi",
														},
													]}
													style={{
														marginBottom: 0,
													}}>
													<Select
														placeholder="Bahan"
														style={{
															width: "100%",
														}}
														showSearch
														onSearch={handleSearch}
														virtual={true}
														loading={isSearching}
														filterOption={(input, option) =>
															option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
														}
														filterSort={(optionA, optionB) =>
															optionA?.children
																?.toLowerCase()
																.localeCompare(optionB?.children?.toLowerCase())
														}>
														{foods.map((food) => (
															<Select.Option key={food._id} value={food._id}>
																{food.name}
															</Select.Option>
														))}
													</Select>
												</Form.Item>
											</Col>
											<Col flex={1}>
												<Form.Item
													{...restField}
													name={[name, "note"]}
													style={{
														marginBottom: 0,
													}}>
													<Input
														placeholder="Catatan"
														style={{
															width: "100%",
														}}
													/>
												</Form.Item>
											</Col>
											<Col flex="none" style={{ alignSelf: "center" }}>
												<MinusCircleOutlined
													style={{
														color: "red",
													}}
													onClick={() => remove(name)}
												/>
											</Col>
										</Row>
									))}
									<Form.Item>
										<Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
											Tambah
										</Button>
									</Form.Item>
								</>
							)}
						</Form.List>
						<Typography.Paragraph
							style={{
								marginTop: 12,
								marginBottom: 0,
								paddingBottom: 8,
							}}>
							Intruksi Pembuatan
							<Tooltip placement="top" title="Tambahkan instruksi pembuatan resep">
								<QuestionCircleOutlined
									style={{
										marginLeft: 4,
										color: "rgba(0, 0, 0, 0.45)",
									}}
								/>
							</Tooltip>
						</Typography.Paragraph>
						<Form.List name="instructions">
							{(fields, { add, remove }) => (
								// instructions {step, title, description, duration}
								<>
									{fields.map(({ key, name, ...restField }) => (
										<Row
											key={key}
											gutter={12}
											style={{
												marginBottom: 12,
											}}>
											<Col flex="none">
												<Form.Item
													{...restField}
													name={[name, "step"]}
													rules={[
														{
															required: true,
															message: "Langkah wajib diisi",
														},
													]}
													style={{
														marginBottom: 0,
													}}>
													<InputNumber
														min={1}
														placeholder="Langkah"
														style={{
															width: "100%",
														}}
													/>
												</Form.Item>
											</Col>
											<Col flex={1}>
												<Form.Item
													{...restField}
													name={[name, "title"]}
													rules={[
														{
															required: true,
															message: "Judul wajib diisi",
														},
													]}
													style={{
														marginBottom: 0,
													}}>
													<Input
														placeholder="Judul"
														style={{
															width: "100%",
														}}
													/>
												</Form.Item>
											</Col>
											<Col flex={1}>
												<Form.Item
													{...restField}
													name={[name, "description"]}
													rules={[
														{
															required: true,
															message: "Deskripsi wajib diisi",
														},
													]}
													style={{
														marginBottom: 0,
													}}>
													<Input
														placeholder="Deskripsi"
														style={{
															width: "100%",
														}}
													/>
												</Form.Item>
											</Col>
											<Col flex="none">
												<Form.Item
													{...restField}
													name={[name, "duration"]}
													rules={[
														{
															required: true,
															message: "Durasi wajib diisi",
														},
													]}
													style={{
														marginBottom: 0,
													}}>
													<InputNumber
														min={1}
														placeholder="Durasi"
														style={{
															width: "100%",
														}}
													/>
												</Form.Item>
											</Col>
											<Col flex="none" style={{ alignSelf: "center" }}>
												<MinusCircleOutlined
													style={{
														color: "red",
													}}
													onClick={() => remove(name)}
												/>
											</Col>
										</Row>
									))}
									<Form.Item>
										<Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
											Tambah
										</Button>
									</Form.Item>
								</>
							)}
						</Form.List>
						<Typography.Paragraph
							style={{
								marginTop: 12,
								marginBottom: 0,
								paddingBottom: 8,
							}}>
							Galeri
							<Tooltip placement="top" title="Tambahkan gambar resep untuk menarik minat pengguna">
								<QuestionCircleOutlined
									style={{
										marginLeft: 4,
										color: "rgba(0, 0, 0, 0.45)",
									}}
								/>
							</Tooltip>
						</Typography.Paragraph>
						{recipe && recipe.images.length > 0 && (
							<Space
								gutter={12}
								wrap
								style={{
									marginBottom: 12,
								}}>
								{recipe.images.map((image, index) => (
									<Image
										src={image.url}
										alt={`recipe-image-${index}`}
										width={80}
										height={80}
										style={{
											objectFit: "cover",
											opacity: deletedImageIds.includes(image._id) ? 0.2 : 1,
										}}
										preview={{
											toolbarRender: (
												_,
												{
													image: { url, alt },
													transform: { scale },
													actions: {
														onFlipY,
														onFlipX,
														onRotateLeft,
														onRotateRight,
														onZoomOut,
														onZoomIn,
														onReset,
														onClose,
													},
												}
											) => (
												<Space size={12} className="image-preview-toolbar-wrapper">
													<DownloadOutlined onClick={() => onDownload(url)} />
													<DeleteOutlined
														onClick={() => {
															onDeleteImage(image._id);
															onClose();
														}}
													/>
													<SwapOutlined rotate={90} onClick={onFlipY} />
													<SwapOutlined onClick={onFlipX} />
													<RotateLeftOutlined onClick={onRotateLeft} />
													<RotateRightOutlined onClick={onRotateRight} />
													<ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
													<ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
													<UndoOutlined onClick={onReset} />
												</Space>
											),
										}}
									/>
								))}
							</Space>
						)}
						{deletedImageIds.length > 0 && (
							<Typography.Text
								type="danger"
								style={{
									display: "block",
									marginBottom: 12,
								}}>
								{deletedImageIds.length} gambar akan dihapus setelah Anda menyimpan data.{" "}
								<Button type="dashed" danger size="small" onClick={() => setDeletedImageIds([])}>
									Batalkan
								</Button>
							</Typography.Text>
						)}
						<Form.Item name="images" noStyle>
							<Upload.Dragger {...draggerProps}>
								<p className="ant-upload-drag-icon">
									<InboxOutlined />
								</p>
								<p className="ant-upload-text">
									Klik atau tarik file gambar ke area ini untuk mengunggah
								</p>
								<p className="ant-upload-hint">
									Tambahkan gambar resep untuk menarik minat pengguna
								</p>
							</Upload.Dragger>
						</Form.Item>
						<Row gutter={12}>
							<Col span={12}>
								<Typography.Paragraph
									style={{
										marginTop: 12,
										marginBottom: 0,
										paddingBottom: 8,
									}}>
									Porsi
								</Typography.Paragraph>
								<Space.Compact style={{ width: "100%" }} direction="horizontal" size="middle">
									<Form.Item
										name={["serving", "amount"]}
										rules={[
											{
												required: true,
												message: "Jumlah saji wajib diisi",
											},
										]}
										noStyle
										style={{
											marginBottom: 0,
										}}>
										<InputNumber
											min={0}
											placeholder="Jumlah Saji"
											style={{
												width: "100%",
											}}
										/>
									</Form.Item>
									<Form.Item
										name={["serving", "unit"]}
										noStyle
										rules={[
											{
												required: true,
												message: "Satuan saji wajib diisi",
											},
										]}
										style={{
											marginBottom: 0,
										}}>
										<Input
											placeholder="Satuan Saji"
											style={{
												width: "100%",
											}}
										/>
									</Form.Item>
								</Space.Compact>
								<Typography.Paragraph
									style={{
										marginTop: 12,
										marginBottom: 0,
										paddingBottom: 8,
									}}>
									Kalori
								</Typography.Paragraph>
								<Space.Compact style={{ width: "100%" }} direction="horizontal" size="middle">
									<Form.Item
										name={["calorie", "total"]}
										rules={[
											{
												required: true,
												message: "Wajib diisi",
											},
										]}
										noStyle
										style={{
											marginBottom: 0,
										}}>
										<InputNumber
											min={0}
											placeholder="Total"
											style={{
												width: "100%",
											}}
										/>
									</Form.Item>
									<Form.Item
										noStyle
										name={["calorie", "carbohydrate"]}
										rules={[
											{
												required: true,
												message: "Wajib diisi",
											},
										]}
										style={{
											marginBottom: 0,
										}}>
										<InputNumber
											min={0}
											placeholder="% Karbohidrat"
											style={{
												width: "100%",
											}}
										/>
									</Form.Item>
									<Form.Item
										noStyle
										name={["calorie", "protein"]}
										rules={[
											{
												required: true,
												message: "Wajib diisi",
											},
										]}
										style={{
											marginBottom: 0,
										}}>
										<InputNumber
											min={0}
											placeholder="% Protein"
											style={{
												width: "100%",
											}}
										/>
									</Form.Item>
									<Form.Item
										name={["calorie", "fat"]}
										noStyle
										rules={[
											{
												required: true,
												message: "Wajib diisi",
											},
										]}
										style={{
											marginBottom: 0,
										}}>
										<InputNumber
											min={0}
											placeholder="% Lemak"
											style={{
												width: "100%",
											}}
										/>
									</Form.Item>
								</Space.Compact>
								<Form.Item
									style={{ marginTop: 12, marginBottom: 0 }}
									label="Tipe"
									name="type"
									rules={[{ required: true, message: "Masukkan tipe" }]}>
									<Radio.Group>
										<Radio value="MAIN">Main Course</Radio>
										<Radio value="DISH">Dish</Radio>
										<Radio value="SNACK">Snack</Radio>
										<Radio value="DESSERT">Dessert</Radio>
										<Radio value="DRINK">Drink</Radio>
										<Radio value="OTHER">Other</Radio>
									</Radio.Group>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Typography.Paragraph
									style={{
										marginTop: 12,
										marginBottom: 0,
										paddingBottom: 8,
									}}>
									Durasi
								</Typography.Paragraph>
								<Space.Compact style={{ width: "100%" }} direction="horizontal" size="middle">
									<Form.Item
										noStyle
										name={["duration", "preparation"]}
										rules={[{ required: true, message: "Masukkan durasi persiapan" }]}>
										<InputNumber style={{ width: "100%" }} placeholder="Persiapan" />
									</Form.Item>
									<Form.Item
										noStyle
										name={["duration", "cooking"]}
										rules={[{ required: true, message: "Masukkan durasi memasak" }]}>
										<InputNumber style={{ width: "100%" }} placeholder="Memasak" />
									</Form.Item>
									<Form.Item
										noStyle
										name={["duration", "rest"]}
										rules={[{ required: true, message: "Masukkan durasi istirahat" }]}>
										<InputNumber style={{ width: "100%" }} placeholder="Istirahat" />
									</Form.Item>
								</Space.Compact>
								<Form.Item
									style={{ marginTop: 12, marginBottom: 0 }}
									label="Experience Point"
									name="xp"
									rules={[{ required: true, message: "Masukkan XP" }]}>
									<InputNumber style={{ width: "100%" }} placeholder="XP" />
								</Form.Item>
								<Form.Item
									style={{ marginTop: 12, marginBottom: 0 }}
									label="Kesulitan"
									name="difficulty"
									rules={[{ required: true, message: "Masukkan kesulitan" }]}>
									<Radio.Group>
										<Radio value="EASY">Mudah</Radio>
										<Radio value="MEDIUM">Sedang</Radio>
										<Radio value="HARD">Sulit</Radio>
									</Radio.Group>
								</Form.Item>
								<Form.Item
									style={{ marginTop: 12, marginBottom: 0 }}
									label="Status"
									name="status"
									rules={[{ required: true, message: "Masukkan status" }]}>
									<Radio.Group>
										<Radio value="DRAFT">Draf</Radio>
										<Radio value="PUBLISHED">Dipublikasikan</Radio>
										<Radio value="ARCHIVED">Arsip</Radio>
									</Radio.Group>
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</Spin>
			</Modal>
		</>
	);
};

export default RecipeFormModal;
