import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Layout, Button, Row, Col, Typography, Form, Input, Card, Checkbox, Spin, App } from "antd";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import axios from "axios";
import AppContext from "../../store/app.context";
import { useEffect } from "react";
import dayjs from "dayjs";

const { Title } = Typography;
const { Footer } = Layout;

const ForgotPasswordPage = (props) => {
	const { message, notification, modal } = App.useApp();

	const context = useContext(AppContext);
	const navigate = useNavigate();

	const [form] = Form.useForm();
	const [isLoading, setLoading] = useState(false);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [isRemembered, setRemembered] = useState(false);
	const [seconds, setSeconds] = useState(60);

	const [page, setPage] = useState(1);

	const rememberMeHandler = (checked) => {
		setRemembered(checked);
	};

	const emailChangeHandler = (event) => {
		setEmail(event.target.value);
	};

	const passwordChangeHandler = (event) => {
		setPassword(event.target.value);
	};

	useEffect(() => {
		if (page === 2) {
			if (seconds > 0) {
				setTimeout(() => setSeconds(seconds - 1), 1000);
			} else {
				setSeconds(60);
			}
		}
	}, [page]);

	const onSubmit = (values) => {
		return;
		form
			.validateFields()
			.then((values) => {
				setLoading(true);
				axios
					.post(process.env.REACT_APP_API_URL + "/auth/request-reset-password", {
						email: values.email,
						password: values.password,
					})
					.then((response) => {
						if (response.data.code === 200) {
							message.success("Halo, selamat datang ^_^");

							context.login(response.data.data.role);
							navigate("/dashboard");
						} else {
							notification["error"]({
								message: "Kesalahan!",
								description: response.data.message,
							});
						}
					})
					.catch((error) => {
						if (error.response !== undefined) {
							let message = error.response.data.message + ". ";
							if (error.response.data.errors !== undefined) {
								Object.keys(error.response.data.errors).forEach((key) => {
									message += `${error.response.data.errors[key]}. `;
								});
							}
							notification["error"]({
								message: "Kesalahan!",
								description: message,
							});
						} else {
							notification["error"]({
								message: "Kesalahan!",
								description: error.message,
							});
						}
					})
					.finally(() => {
						setLoading(false);
					});
			})
			.catch((error) => {});
	};

	const onError = (errorInfo) => {};

	return (
		<>
			{isLoading && <Spin className="container-spin" size="large" />}
			<Row type="flex" justify="center" align="middle" style={{ minHeight: "100vh" }}>
				<Col xs={24} sm={24} md={12} lg={12} xl={8}>
					{page === 1 && (
						<Card style={{ margin: "24px" }}>
							<Title level={4}>Lupa Password</Title>
							<p>Masukkan email Anda untuk mendapatkan kode reset password</p>
							<Form
								form={form}
								className="login-form"
								initialValues={{ remember: true }}
								onFinishFailed={onError}
								onFinish={onSubmit}>
								<Form.Item
									onChange={emailChangeHandler}
									name="email"
									rules={[{ required: true, message: "Masukkan email Anda" }]}>
									<Input
										size="large"
										prefix={<MailOutlined className="site-form-item-icon" />}
										placeholder="Email"
									/>
								</Form.Item>

								<Form.Item>
									<Button
										type="primary"
										htmlType="submit"
										className="login-form-button"
										size="large">
										Kirim Kode
									</Button>
								</Form.Item>
								<p className="font-semibold text-muted text-center">
									Belum punya akun?{" "}
									<Link to="/register" className="font-bold text-dark">
										Daftar
									</Link>
								</p>
							</Form>
							<Footer style={{ marginTop: 24 }}>
								<p className="copyright auth">
									Copyright © {new Date().getFullYear()} Nutriva by
									<a href="https://codeiva.com">Codeiva</a>.
								</p>
							</Footer>
						</Card>
					)}
					{page === 2 && (
						<Card style={{ margin: "24px" }}>
							<Title level={4}>Reset Password</Title>
							<p>Masukkan kode reset password yang telah dikirimkan ke email Anda</p>
							<Form
								className="login-form"
								initialValues={{ remember: true }}
								onFinishFailed={onError}
								onFinish={onSubmit}>
								<Form.Item
									name="token"
									rules={[{ required: true, message: "Masukkan kode yang Anda terima" }]}>
									<Input
										size="large"
										prefix={<MailOutlined className="site-form-item-icon" />}
										placeholder="Kode"
									/>
								</Form.Item>
								<Form.Item
									onChange={passwordChangeHandler}
									name="password"
									rules={[{ required: true, message: "Masukkan password baru" }]}>
									<Input
										size="large"
										prefix={<MailOutlined className="site-form-item-icon" />}
										placeholder="Password Baru"
									/>
								</Form.Item>
								<Form.Item
									onChange={passwordChangeHandler}
									name="password-confirmation"
									rules={[{ required: true, message: "Masukkan konfirmasi password" }]}>
									<Input
										size="large"
										prefix={<MailOutlined className="site-form-item-icon" />}
										placeholder="Konfirmasi Password"
									/>
								</Form.Item>

								{/* Timer to resend */}
								<Typography.Text type="secondary">
									Kirim ulang kode dalam {dayjs().minute(seconds).format("mm:ss")}
								</Typography.Text>

								<Form.Item>
									<Button
										type="primary"
										htmlType="submit"
										className="login-form-button"
										size="large">
										Reset
									</Button>
								</Form.Item>
								<p className="font-semibold text-muted text-center">
									Belum punya akun?{" "}
									<Link to="/register" className="font-bold text-dark">
										Daftar
									</Link>
								</p>
							</Form>
							<Footer style={{ marginTop: 24 }}>
								<p className="auth">
									<a href="#nutriva" className="font-bold text-dark">
										Nutriva
									</a>{" "}
									© {new Date().getFullYear()} by
									<a href="https://codeiva.com">Codeiva</a>.
								</p>
							</Footer>
						</Card>
					)}
				</Col>
			</Row>
		</>
	);
};

export default ForgotPasswordPage;
