/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { ExclamationCircleFilled, MoreOutlined, PlusCircleFilled } from "@ant-design/icons";
import { App, Button, Card, Col, Dropdown, Row, Space, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { requestGetFoodList } from "../../../services/master.service";
import dayjs from "dayjs";
import { HiOutlinePencil, HiOutlineTrash } from "react-icons/hi2";
import SearchInput from "../../../components/SearchInput";
import FoodFormModal from "./components/FoodFormModal";

const columns = [
	{
		title: "Code",
		dataIndex: "code",
		key: "code",
		sorter: true,
	},
	{
		title: "Name",
		dataIndex: "name",
		key: "name",
	},
	{
		title: "Source",
		dataIndex: "source",
		key: "source",
	},
	{
		title: "Aksi",
		dataIndex: "actions",
		key: "actions",
		align: "right",
	},
];

const otherButtons = [
	{
		key: "edit",
		label: "Edit",
		icon: <HiOutlinePencil />,
	},
	{
		type: "divider",
	},
	{
		key: "delete",
		label: "Hapus",
		icon: <HiOutlineTrash />,
		danger: true,
	},
];

const FoodPage = () => {
	const { message, notification, modal } = App.useApp();
	const [groups, setGroups] = useState([]);
	const [types, setTypes] = useState([]);

	// SEARCH, FILTER, PAGINATION
	const [keyword, setKeyword] = useState("");
	const [filter, setFilter] = useState({
		startedAt: dayjs().startOf("month").format("YYYY-MM-DD"),
		endedAt: dayjs().endOf("month").format("YYYY-MM-DD"),
	});
	const [sorter, setSorter] = useState({
		_id: "desc",
	});
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);

	const handleSearch = (value) => {
		setKeyword(value.trim());
	};

	const handlePaginate = (pagination, filters, sorter) => {
		setPage(pagination.current);
		setLimit(pagination.pageSize);

		// UPDATE FILTER
		if (filters) {
			const additionalFilter = {};
			Object.keys(filters).forEach((key) => {
				if (filters[key]) {
					additionalFilter[key] = filters[key].join("|");
				} else {
					additionalFilter[key] = "";
				}
			});

			setFilter({
				...filter,
				...additionalFilter,
			});
		} else {
			const tempFilter = {};
			// only allow startedAt and endedAt
			Object.keys(filter).forEach((key) => {
				if (key === "startedAt" || key === "endedAt") {
					tempFilter[key] = filter[key];
				}
			});

			setFilter(tempFilter);
		}

		// UPDATE SORTER
		if (sorter) {
			setSorter({
				[sorter.field]: sorter.order === "ascend" ? "asc" : "desc",
			});
		} else {
			setSorter({
				_id: "desc",
			});
		}
	};
	// SEARCH, FILTER, PAGINATION

	const [foods, setFoods] = useState([]);
	const [mappedFoods, setMappedFoods] = useState([]);
	const [isLoading, setLoading] = useState(false);

	const [food, setFood] = useState(null);
	const [isFoodFormVisible, setFoodFormVisible] = useState(false);

	useEffect(() => {
		fetchFoodList();
	}, [keyword, filter, page, limit]);

	const fetchFoodList = () => {
		setLoading(true);
		let tempFilter = "";
		if (filter) {
			Object.keys(filter).forEach((key) => {
				if (filter[key]) {
					tempFilter += `${key}:${filter[key]},`;
				}
			});
		}

		let tempSorter = "";
		if (sorter) {
			Object.keys(sorter).forEach((key) => {
				if (sorter[key]) {
					tempSorter += `${key}:${sorter[key]}`;
				}
			});
		}

		requestGetFoodList({
			page: page,
			limit: limit,
			order: tempSorter ?? "_id:desc",
			keyword: keyword ?? "",
			filter: tempFilter ?? "",
		})
			.then((response) => {
				setFoods(response.data.data);
				updateMappedFood(response.data.data);
				setTotal(response.data.pagination.total);

				setGroups(response.data.groups);
				setTypes(response.data.types);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const updateMappedFood = (data) => {
		setMappedFoods(
			data.map((d) => ({
				key: d._id,
				code: d.code,
				name: d.name,
				source: d.source,
				actions: (
					<Space>
						<Dropdown
							trigger={["click"]}
							menu={{
								onClick: (e) => {
									if (e.key === "edit") {
										handleEditButton(d);
									} else if (e.key === "delete") {
										modal.confirm({
											title: "Sebentar",
											icon: <ExclamationCircleFilled />,
											content:
												"Anda yakin akan menghapus makanan ini? Tindakan ini tidak dapat dibatalkan. Semua data terkait akan terputus.",
											okText: "Ya",
											okType: "danger",
											cancelText: "Tidak",
											onOk() {
												handleDeleteButton(d._id);
											},
											onCancel() {},
										});
									}
								},
								items: otherButtons,
							}}
							placement="bottomRight"
							arrow>
							<Button type="default" shape="circle" size="small" icon={<MoreOutlined />} />
						</Dropdown>
					</Space>
				),
			}))
		);
	};

	const handleAddButton = () => {
		setFoodFormVisible(true);
	};

	const handleEditButton = (food) => {
		setFood(food);
		setFoodFormVisible(true);
	};

	const handleDeleteButton = (id) => {
		// setLoading(true);
		// deleteFood(id)
		// 	.then((response) => {
		// 		if (response.data.code === 200) {
		// 			notification["success"]({
		// 				message: "Good job!",
		// 				description: response.data.message,
		// 			});
		// 			fetchFoodList();
		// 		}
		// 	})
		// 	.catch((error) => {
		// 		if (error.response && error.response.data) {
		// 			notification["error"]({
		// 				message: "Kesalahan!",
		// 				description: error.response.data.message,
		// 			});
		// 		} else {
		// 			notification["error"]({
		// 				message: "Kesalahan!",
		// 				description: error.message,
		// 			});
		// 		}
		// 	})
		// 	.finally(() => {
		// 		setLoading(false);
		// 	});
	};

	const handleOnCloseFoodForm = () => {
		setFood(null);
		setFoodFormVisible(false);
	};

	const handleOnSuccessFoodForm = () => {
		setFood(null);
		setFoodFormVisible(false);
		fetchFoodList();
	};

	return (
		<>
			<FoodFormModal
				isLoading={isLoading}
				isVisible={isFoodFormVisible}
				onLoading={(v) => setLoading(v)}
				food={food}
				groups={groups}
				types={types}
				onClose={handleOnCloseFoodForm}
				onSuccess={handleOnSuccessFoodForm}
			/>
			<div className="tabled">
				<Row gutter={[24, 0]}>
					<Col xs={24} xl={24}>
						<Card
							bordered={false}
							className="circlebox tablespace mb-24"
							title="Daftar Makanan"
							extra={
								<Space>
									<SearchInput placeholder="Kode atau nama" onSearch={handleSearch} />
									<Button icon={<PlusCircleFilled />} type="primary" onClick={handleAddButton}>
										<span className="ant-btn-text">Tambah</span>
									</Button>
								</Space>
							}>
							<div>
								<Table
									columns={columns}
									loading={isLoading}
									dataSource={mappedFoods}
									pagination={{
										total: total,
										position: "bottomRight",
										pageSizeOptions: ["10", "20", "50", "100"],
										showSizeChanger: true,
										locale: { items_per_page: "item/hal" },
									}}
									onChange={handlePaginate}
									className="ant-border-space"
								/>
							</div>
						</Card>
					</Col>
				</Row>
			</div>
		</>
	);
};

export default FoodPage;
