import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
	Layout,
	Button,
	Typography,
	Card,
	Form,
	Input,
	Checkbox,
	Spin,
	Row,
	Col,
	App,
	Radio,
} from "antd";
import axios from "axios";

const { Title } = Typography;
const { Footer } = Layout;
const RegisterPage = () => {
	const { message, notification, modal } = App.useApp();

	const navigate = useNavigate();

	const [form] = Form.useForm();
	const [isLoading, setLoading] = useState(false);
	const [isAgreed, setAgreed] = useState(false);

	const agreementHandler = (checked) => {
		setAgreed(checked);
	};

	const onSubmit = (values) => {
		form
			.validateFields()
			.then((values) => {
				if (values.phone.startsWith("0")) {
					values.phone = values.phone.replace("0", "");
				}
				if (values.phone.startsWith("62")) {
					values.phone = values.phone.replace("62", "");
				}
				if (values.phone.startsWith("+62")) {
					values.phone = values.phone.replace("+62", "");
				}

				values.phone = "+62" + values.phone.replace(/\D/g, "");
				setLoading(true);
				axios
					.post(process.env.REACT_APP_API_URL + "/auth/register", {
						name: values.name,
						email: values.email,
						phone: values.phone,
						password: values.password,
						role: values.role,
						number: values.number,
					})
					.then((response) => {
						if (response.data.code === 200) {
							message.success("Berhasil daftar, silahkan login ^_^");

							navigate("/login");
						} else {
							notification["error"]({
								message: "Kesalahan!",
								description: response.data.message,
							});
						}
					})
					.catch((error) => {
						if (error.response !== undefined) {
							let message = error.response.data.message + ". ";
							if (error.response.data.errors !== undefined) {
								Object.keys(error.response.data.errors).forEach((key) => {
									message += `${error.response.data.errors[key]}. `;
								});
							}
							notification["error"]({
								message: "Kesalahan!",
								description: message,
							});
						} else {
							notification["error"]({
								message: "Kesalahan!",
								description: error.message,
							});
						}
					})
					.finally(() => {
						setLoading(false);
					});
			})
			.catch((error) => {});
	};

	const onError = (error) => {};

	return (
		<>
			{isLoading && <Spin className="container-spin" size="large" />}
			<Row type="flex" justify="center" align="middle" style={{ minHeight: "100vh" }}>
				<Col xs={24} sm={24} md={12} lg={12} xl={8}>
					<Card style={{ margin: "24px" }}>
						<Title level={4}>Daftar</Title>
						<p>Pastikan menggunakan data yang benar ya</p>
						<Form
							form={form}
							className="login-form"
							initialValues={{ remember: true }}
							onFinishFailed={onError}
							onFinish={onSubmit}>
							{/* radio user role */}
							<Form.Item
								name="role"
								rules={[{ required: true, message: "Pilih role Anda" }]}
								required>
								<Radio.Group
									size="large"
									options={[
										{ label: "Guru", value: "TEACHER" },
										{ label: "Siswa", value: "STUDENT" },
										{ label: "Wali", value: "PARENT" },
									]}
									optionType="button"
									buttonStyle="solid"
								/>
							</Form.Item>
							<Form.Item
								name="number"
								rules={[{ required: true, message: "Masukkan nomor ID Anda" }]}>
								<Input size="large" placeholder="Nomor ID" />
							</Form.Item>
							<Form.Item name="name" rules={[{ required: true, message: "Masukkan nama Anda" }]}>
								<Input size="large" placeholder="Nama" />
							</Form.Item>
							{/* Note */}
							<Typography.Text
								type="secondary"
								style={{
									display: "block",
									marginBottom: "16px",
								}}>
								Nama yang Anda masukkan akan digunakan sebagai nama akun Anda. Nama ini bisa berbeda
								dengan data yang tertera di kartu identitas Anda.
							</Typography.Text>
							<Form.Item name="email" rules={[{ required: true, message: "Masukkan email Anda" }]}>
								<Input size="large" placeholder="Email" />
							</Form.Item>
							<Form.Item
								name="phone"
								rules={[{ required: true, message: "Masukkan nomor telepon Anda" }]}>
								<Input size="large" addonBefore="+62" placeholder="No. Telepon" />
							</Form.Item>
							<Typography.Text
								type="secondary"
								style={{
									display: "block",
									marginBottom: "16px",
								}}>
								Masukkan nomor telepon tanpa angka 0 di depannya.
							</Typography.Text>
							<Form.Item
								name="password"
								rules={[{ required: true, message: "Masukkan password Anda" }]}>
								<Input.Password size="large" type="password" placeholder="Password" />
							</Form.Item>
							<Form.Item>
								<Form.Item
									name="remember"
									valuePropName="checked"
									noStyle
									onChange={agreementHandler}>
									<Checkbox>
										Saya setuju{" "}
										<a href="#nutriva" className="font-bold text-dark">
											syarat dan ketentuan yang berlaku
										</a>
									</Checkbox>
								</Form.Item>
							</Form.Item>

							<Form.Item>
								<Button type="primary" htmlType="submit" className="login-form-button" size="large">
									Daftar
								</Button>
							</Form.Item>
							<p className="font-semibold text-muted text-center">
								Sudah punya akun?{" "}
								<Link to="/login" className="font-bold text-dark">
									Masuk
								</Link>
							</p>
						</Form>
						<Footer style={{ marginTop: 24 }}>
							<p className="copyright auth">
								Copyright © {new Date().getFullYear()} Nutriva by
								<a href="https://codeiva.com">Codeiva</a>.
							</p>
						</Footer>
					</Card>
				</Col>
			</Row>
		</>
	);
};

export default RegisterPage;
