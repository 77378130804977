/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Form, Input, Modal, App, Popconfirm, Spin, Typography, Radio } from "antd";
import React, { useEffect } from "react";
import { requestAddFood, requestEditFood } from "../../../../services/master.service";

const FoodFormModal = ({
	food,
	groups,
	types,
	isVisible,
	isLoading,
	onLoading,
	onClose,
	onSuccess,
}) => {
	const { message, notification, modal } = App.useApp();
	const [role, setRole] = React.useState(null);

	const [form] = Form.useForm();

	useEffect(() => {
		if (food) {
			form.setFieldsValue({
				region: food.region,
				group: food.group,
				type: food.type,
				code: food.code,
				name: food.name,
				source: food.source,
			});
		} else {
			form.setFieldsValue({
				region: "ID",
				group: "",
				type: "",
				code: "",
				name: "",
				source: "",
			});
		}
	}, [food]);

	const handleSubmit = () => {
		form
			.validateFields()
			.then((values) => {
				onLoading(true);
				if (food) {
					values.id = food._id;
					requestEditFood(values)
						.then((response) => {
							notification["success"]({
								message: "Good job!",
								description: response.data.message,
							});
							form.resetFields();
							onSuccess();
						})
						.catch((error) => {
							if (error.response && error.response.data) {
								notification["error"]({
									message: "Kesalahan!",
									description: error.response.data.message,
								});
							} else {
								notification["error"]({
									message: "Kesalahan!",
									description: error.message,
								});
							}
						})
						.finally(() => {
							onLoading(false);
						});
				} else {
					requestAddFood(values)
						.then((response) => {
							notification["success"]({
								message: "Good job!",
								description: response.data.message,
							});
							form.resetFields();
							onSuccess();
						})
						.catch((error) => {
							if (error.response && error.response.data) {
								notification["error"]({
									message: "Kesalahan!",
									description: error.response.data.message,
								});
							} else {
								notification["error"]({
									message: "Kesalahan!",
									description: error.message,
								});
							}
						})
						.finally(() => {
							onLoading(false);
						});
				}
			})
			.catch((error) => {});
	};

	const handleClose = () => {
		form.resetFields();
		onClose();
	};

	return (
		<>
			<Modal
				width={512}
				title={food === null || food === undefined ? "Tambah Makanan" : "Ubah Makanan"}
				open={isVisible}
				cancelText="Batal"
				onCancel={handleClose}
				footer={[
					<Button key={1} onClick={handleClose}>
						Batal
					</Button>,
					<Popconfirm
						key={2}
						onConfirm={handleSubmit}
						title="Yakin akan menyimpan data makanan?"
						okText="Ya"
						cancelText="Tidak">
						<Button type="primary">{food ? "Simpan" : "Tambah"}</Button>
					</Popconfirm>,
				]}>
				<Spin spinning={isLoading}>
					<Form form={form} layout="vertical" requiredMark="optional">
						<Form.Item
							style={{ marginTop: 12, marginBottom: 0 }}
							label="Region"
							name="region"
							rules={[{ required: true, message: "Pilih region" }]}>
							<Radio.Group>
								<Radio value="ID">Indonesia</Radio>
							</Radio.Group>
						</Form.Item>
						{/* groups and types */}
						<Form.Item
							style={{ marginTop: 12, marginBottom: 0 }}
							label="Grup"
							name="group"
							rules={[{ required: true, message: "Pilih grup" }]}>
							<Radio.Group>
								{groups.map((group) => (
									<Radio key={group.code} value={group.code}>
										{group.name}
									</Radio>
								))}
							</Radio.Group>
						</Form.Item>
						<Form.Item
							style={{ marginTop: 12, marginBottom: 0 }}
							label="Tipe"
							name="type"
							rules={[{ required: true, message: "Pilih tipe" }]}>
							<Radio.Group>
								{types.map((type) => (
									<Radio key={type.code} value={type.code}>
										{type.name}
									</Radio>
								))}
							</Radio.Group>
						</Form.Item>
						<Form.Item
							style={{ marginTop: 12, marginBottom: 0 }}
							name="code"
							label="Kode"
							rules={[{ required: true, message: "Masukkan kode" }]}>
							<Input style={{ width: "100%" }} placeholder="Kode" />
						</Form.Item>
						<Form.Item
							style={{ marginTop: 12, marginBottom: 0 }}
							label="Nama"
							name="name"
							rules={[{ required: true, message: "Masukkan nama" }]}>
							<Input style={{ width: "100%" }} placeholder="Nama" />
						</Form.Item>
						<Form.Item
							style={{ marginTop: 12, marginBottom: 0 }}
							label="Sumber"
							name="source"
							rules={[{ required: true, message: "Masukkan sumber" }]}>
							<Input style={{ width: "100%" }} placeholder="Sumber" />
						</Form.Item>
					</Form>
				</Spin>
			</Modal>
		</>
	);
};

export default FoodFormModal;
